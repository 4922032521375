
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class ChangePassword extends Vue {
    public passwordInputType = 'password';
    public new_password = '';
    public password = '';
}
