
import { Component, Vue } from 'vue-property-decorator';
import ReviewUnknown from '@/components/modals/ReviewUnknown.vue';
import ReviewUser from '@/components/modals/ReviewUser.vue';
import ReviewShare from '@/components/modals/ReviewShare.vue';
import ChangePassword from '@/components/modals/ChangePassword.vue';
import { dispatchRegisterUser } from '@/store/main/actions';
import { IUserRegister } from '@/interfaces';
import MainHeader from '@/components/header/MainHeader.vue';
import { mask } from 'vue-the-mask';
// import Header from '@/components/header/Header__.vue'

@Component({
  directives: {mask},
    components: {
        ReviewUnknown,
        ReviewUser,
        ReviewShare,
        ChangePassword,
        MainHeader,
        // Header
    },
})

export default class SignUpStep3 extends Vue {
    public file = null;
    public userData: IUserRegister = {
        user_type: '',
        title: '',
        phone: '',
        password: '',
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        country: '',
        region: '',
        city: '',
        nova_poshta_number: 0,
        language: '',
        delivery_address: '',
        site_link: '',
        documents: '',
    }
    public firstNameError = '';
    public emailError = '';
    public passwordError = '';
    public fakeField = '';
    public confirmPassword = '';
    public passwordInputType = 'password';
    public loadingState = {
        blockBtn: false,
    };

    //   public mounted() {
    //   }

    public async submit() {
        if (!this.userData.email || (this.userData.email && !/(.+)@(.+){1,}\.(.+){2,}/.test(this.userData.email))) {
            return false;
        }
        if (this.userData.password === '' || this.confirmPassword !== this.userData.password){
            return false;
        }
        this.loadingState.blockBtn = true;
        const response = await dispatchRegisterUser(this.$store,
        {
            user_type: this.userData.user_type,
            title: this.userData.title,
            phone: this.userData.phone,
            password: this.userData.password,
            email: this.userData.email,
            first_name: this.userData.first_name,
            last_name: this.userData.last_name,
            middle_name: this.userData.middle_name,
            country: this.userData.country,
            region: this.userData.region,
            city: this.userData.city,
            nova_poshta_number: this.userData.nova_poshta_number,
            language: this.userData.language,
            delivery_address: this.userData.delivery_address,
            site_link: this.userData.site_link,
            documents: this.userData.documents,
        },
    );
    if (response !== true) {
      this.firstNameError = response.first_name ? response.first_name[0] : '';
      this.emailError = response.email ? response.email[0] : '';
      this.passwordError = response.password ? response.password[0] : '';
    } else {
      this.firstNameError = '';
      this.emailError = '';
      this.passwordError = '';
      await this.$router.push('/home');
    }
    this.loadingState.blockBtn = false;
  }
}
